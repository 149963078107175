// Payment form btw disable logic -> checkout form component

import { useEffect, useState } from 'react'
import { Button, CircularProgress } from '@mui/material'
import {
  ExpressCheckoutElement,
  PaymentRequestButtonElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { StripeExpressCheckoutElementOptions } from '@stripe/stripe-js'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import { SetPaymentDone, SetSlotBookedAlready, SetSlotNotFound } from 'redux/persist'
// import { analytics } from 'index'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { StatusEnum } from 'redux/types'
import styles from './Payment.module.scss'

export function CheckoutForm({ clientSecret, planId, disableBackButton, enableBackButton, userInfo }: any) {
  const stripe = useStripe()
  const elements = useElements()
  //  const authState = useAppSelector(selectAuthState)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  //   const purchasePlanAuthSecret = authState.purchasePlanAuthSecret
  //   const clientSecret: any = purchasePlanAuthSecret?.clientSecret

  const [pollId, setPollId] = useState<any>(null)
  const [payment, setPayment] = useState('')
  const [buttonDisable, setButtonDisable] = useState(false)
  const [stripePaymentStatus, setStripePaymentStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [paymentStatus, setPaymentStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [paymentData, setPaymentData] = useState<any>(null)
  const selectedSlot = useAppSelector((state) => state.selectedSlot.selectedSlot)
  const selectedPlanInfo = useAppSelector((state) => state.selectedPlanInfo.selectedPlanInfo)
  const [isPaymentElementFilled, setIsPaymentElementFilled] = useState(false)

  const handlePaymentElementChange = (event: any) => {
    setIsPaymentElementFilled(event.complete)
  }

  async function getPayment(planId: any) {
    try {
      const response: any = await axios
        .get(`${AppConstants.API_URL}/payment-plan/${planId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          if (res && res?.data && res?.data?.data) {
            setPaymentData(res?.data?.data)
            setPaymentStatus(StatusEnum.Success)
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  //to schedule physical visit

  const schedulePhysicalVisit = async () => {
    if (selectedSlot?.scheduleObject) {
      const response: any = await axios
        .post(AppConstants.API_URL + '/visit/chiropractor/appointment', selectedSlot.scheduleObject, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
          },
        })
        .then((res) => {
          return res
        })
        .catch((err) => {
          return err.response
        })

      if (response?.data?.code === 'success') {
        const navigationTimeout = setTimeout(() => {
          navigate('/welcome')
        }, 3000)
        return () => {
          clearTimeout(navigationTimeout)
        }
      } else {
        if (response?.data?.code === 'slot_booked_already') {
          dispatch(SetSlotBookedAlready(true))
          const navigationTimeout = setTimeout(() => {
            navigate('/physical-visit')
          }, 3000)
          return () => {
            clearTimeout(navigationTimeout)
          }
        } else if (response?.data?.code === 'slot_not_found') {
          dispatch(SetSlotNotFound(true))
          const navigationTimeout = setTimeout(() => {
            navigate('/physical-visit')
          }, 3000)
          return () => {
            clearTimeout(navigationTimeout)
          }
        } else {
        }

        return false
      }
    } else {
      return false
    }
  }

  useEffect(() => {
    if (stripePaymentStatus === StatusEnum.Success && !pollId) {
      let intId = setInterval(() => {
        if (planId) {
          getPayment(planId)
        }
        if (paymentData?.status === 'completed' && pollId) {
          clearInterval(pollId)
        }
      }, 1000)
      setPollId(intId)
    }

    return () => {
      if (pollId) {
        clearInterval(pollId)
      }
    }
  }, [stripePaymentStatus, pollId])

  useEffect(() => {
    if (paymentStatus === StatusEnum.Success && paymentData?.status === 'completed') {
      clearInterval(pollId)
      setPayment('success')
      dispatch(SetPaymentDone(true))
      // analytics.identify(userInfo?.id, {
      //   name: `${userInfo?.firstname}` + `${userInfo?.lastname}`,
      //   email: `${userInfo?.email}`,
      // })
      // analytics.track('First Order Completed', {
      //   value: purchaseAmount,
      //   currency: 'USD',
      //   order_id: planId,
      // })

      if (selectedPlanInfo) {
        // ReactGA.event('conversion', {
        //   send_to: process.env.REACT_APP_GA_CONVERSION_ID || 'AW-11337579902/40e2CN_xnqkZEP76lp4q',
        //   value: selectedPlanInfo.amount / 100,
        //   currency: 'USD',
        // })
        console.log({
          currency: 'USD',
          value: selectedPlanInfo.amount / 100,
          items: [
            {
              item_id: selectedPlanInfo.id,
              item_name: selectedPlanInfo.name,
            },
          ],
        })
        ReactGA.event('purchase', {
          currency: 'USD',
          value: selectedPlanInfo.amount / 100,
          items: [
            {
              item_id: selectedPlanInfo.id,
              item_name: selectedPlanInfo.name,
            },
          ],
        })
      }
      schedulePhysicalVisit()
      // const navigationTimeout = setTimeout(() => {
      //   navigate('/introductory-screening')
      // }, 3000)
      // return () => {
      //   clearTimeout(navigationTimeout)
      // }
    }
  }, [paymentStatus])

  const handlePayment = async (e: any) => {
    e.preventDefault()
    if (!stripe || !elements) {
      return
    }

    if (!buttonDisable) {
      setButtonDisable(true)
      let stripeValidation = await elements.submit()

      if (stripeValidation.error) {
        console.log(stripeValidation.error)
        setButtonDisable(false)
        return
      } else {
        setPayment('in_progress')
        disableBackButton()
      }

      stripe
        .confirmPayment({
          elements,
          clientSecret,
          redirect: 'if_required',
          // confirmParams: {
          //   return_url: '',
          // },
        })
        .then((response) => {
          if (response.paymentIntent?.status === 'succeeded') {
            setStripePaymentStatus(StatusEnum.Success)
          } else {
            setStripePaymentStatus(StatusEnum.Failed)
            setPayment('')
            enableBackButton()
            alert('Payment failed. Please try again')
          }
        })
        .catch((error) => {})
    }
  }
  const expressCheckoutOptions: StripeExpressCheckoutElementOptions = {
    buttonTheme: {
      applePay: 'white',
      googlePay: 'white',
    },
    buttonType: {
      applePay: 'buy',
      googlePay: 'buy',
    },
    wallets: {
      googlePay: 'always',
      applePay: 'always',
    },
  }

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'payment',
      page_location: '/payment',
    })
  }, [])

  return (
    <>
      <form
        onSubmit={isPaymentElementFilled ? handlePayment : () => {}}
        style={{ flex: 1, position: 'relative', display: 'flex', flexDirection: 'column' }}
      >
        <div className={styles.paymentForm}>
          <PaymentElement
            onChange={handlePaymentElementChange}
            options={{
              layout: 'accordion',
              paymentMethodOrder: ['card', 'applePay'],
              wallets: {
                applePay: 'auto',
                googlePay: 'auto',
              },
            }}
          />
        </div>
        <div className={styles.footer}>
          <Button
            type={isPaymentElementFilled ? 'submit' : undefined}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2rem',
              opacity: isPaymentElementFilled ? '1' : '0.5',
            }}
            style={
              payment === 'success'
                ? {
                    backgroundImage: "url('images/doneImg.svg')",
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                  }
                : {}
            }
          >
            <span>
              {payment !== 'success' &&
                payment !== 'in_progress' &&
                `Pay $${selectedPlanInfo && selectedPlanInfo.amount / 100}`}
            </span>
            {payment === 'in_progress' && (
              <CircularProgress
                size={20}
                className={styles.progressBar}
                sx={{ color: 'white', width: '14px', height: '14px', left: '90%' }}
              />
            )}
          </Button>
        </div>
      </form>
    </>
  )
}
