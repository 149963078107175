import { Offers_Flow_Action_Type } from 'AppConstants/AppConstants'
import { studio } from 'redux/persist'
import { StatusEnum } from 'redux/types'

//Selected Slot Persist
export interface ITime {
  index: number
  value: string
}

interface IScheduleInitialValues {
  scheduleObject: {
    appointmentTime: string
    timezone: string
    clinicId: string | undefined | null
  }
  index?: number | null
  value?: ITime | null
  date?: any
}
interface SelectedSlotOfferFlowState {
  selectedSlotOfferFlow: IScheduleInitialValues | null
}
const selectedSlotOfferFlowState: SelectedSlotOfferFlowState = {
  selectedSlotOfferFlow: null,
}

export const SetSelectedSlotOfferFlow = (selectedSlotOfferFlow: IScheduleInitialValues | null) => ({
  type: Offers_Flow_Action_Type.SELECTED_SLOT_OFFERS_FLOW,
  payload: selectedSlotOfferFlow,
})

export const selectedSlotOfferFlowPersist = (
  state = selectedSlotOfferFlowState,
  action: { type: Offers_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.SELECTED_SLOT_OFFERS_FLOW:
      return {
        ...state,
        selectedSlotOfferFlow: action.payload as IScheduleInitialValues,
      }
    default:
      return state
  }
}

// Payment Plan Persist

export interface IPlan {
  id: string
  name: string
  description: string
  campaignName: string
  type: string
  isDefault: boolean
  membershipPrice: number | null
  applicableToAllCycle: boolean | null
  stripeProductId: string
  count: number
  amount: number
  frequency: string | null
  maxCount: number
  isActive: boolean
  createdAt: string
  updatedAt: string
}

interface PaymentPlans {
  plans: IPlan[]
}
const paymentPlansState: PaymentPlans = {
  plans: [],
}

export const SetPaymentPlansOfferFlow = (paymentPlans: IPlan[]) => ({
  type: Offers_Flow_Action_Type.PAYMENT_PLAN_OFFERS_FLOW,
  payload: paymentPlans,
})

export const paymentPlanOfferFlowPersist = (
  state = paymentPlansState,
  action: { type: Offers_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.PAYMENT_PLAN_OFFERS_FLOW:
      return {
        ...state,
        plans: action.payload as Array<IPlan>,
      }
    default:
      return state
  }
}

// purchased payment plan  Persist

interface PurchasedPlan {
  clientSecret: string
  paymentId: string
  paymentIntentId: string
}

interface PurchasedPlanPersistState {
  purchasedPlan: PurchasedPlan | null
}
const purchasedPlanPersistState: PurchasedPlanPersistState = {
  purchasedPlan: null,
}

export const SetPurchasedPlanOfferFlow = (purchasedPlan: PurchasedPlan | null) => ({
  type: Offers_Flow_Action_Type.PURCHASED_PLAN_OFFERS_FLOW,
  payload: purchasedPlan,
})

export const purchasedPlanOfferFlowPersist = (
  state = purchasedPlanPersistState,
  action: { type: Offers_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.PURCHASED_PLAN_OFFERS_FLOW:
      return {
        ...state,
        purchasedPlan: action.payload as PurchasedPlan,
      }
    default:
      return state
  }
}

// Payment Done Persist

interface PaymentDone {
  paymentDone: boolean
}
const paymentDoneState: PaymentDone = {
  paymentDone: false,
}

export const SetPaymentDoneOfferFlow = (paymentDone: boolean) => ({
  type: Offers_Flow_Action_Type.PAYMENT_DONE_OFFERS_FLOW,
  payload: paymentDone,
})

export const paymentDoneOfferFlowPersist = (
  state = paymentDoneState,
  action: { type: Offers_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.PAYMENT_DONE_OFFERS_FLOW:
      return {
        ...state,
        paymentDone: action.payload,
      }
    default:
      return state
  }
}

// Slot booked already Persist

interface SlotBookedType {
  slotBookedAlready: boolean
}
const slotBookedAlreadyState: SlotBookedType = {
  slotBookedAlready: false,
}

export const SetSlotBookedAlreadyOfferFlow = (slotBooked: boolean) => ({
  type: Offers_Flow_Action_Type.SLOT_BOOKED_ALREADY_OFFERS_FLOW,
  payload: slotBooked,
})

export const slotBookedOfferFlowPersist = (
  state = slotBookedAlreadyState,
  action: { type: Offers_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.SLOT_BOOKED_ALREADY_OFFERS_FLOW:
      return {
        ...state,
        slotBookedAlready: action.payload,
      }
    default:
      return state
  }
}

// Slot not found Persist

interface SlotNotFoundErrType {
  slotNotFound: boolean
}
const slotNotFoundState: SlotNotFoundErrType = {
  slotNotFound: false,
}

export const SetSlotNotFoundOfferFlow = (slotNotFound: boolean) => ({
  type: Offers_Flow_Action_Type.SLOT_NOT_FOUND_OFFERS_FLOW,
  payload: slotNotFound,
})

export const slotNotFoundOfferFlowPersist = (
  state = slotNotFoundState,
  action: { type: Offers_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.SLOT_NOT_FOUND_OFFERS_FLOW:
      return {
        ...state,
        slotNotFound: action.payload,
      }
    default:
      return state
  }
}

// visitCount Persist

interface VisitCount {
  onDemandCount: number | null
  subscriptionCount: number | null
  previousVisits: boolean
}

interface VisitCountState {
  visitCount: VisitCount | null
}
const visitCountState: VisitCountState = {
  visitCount: null,
}

export const SetVisitCountOfferFlow = (visitCount: VisitCount | null) => ({
  type: Offers_Flow_Action_Type.VISIT_COUNT_OFFERS_FLOW,
  payload: visitCount,
})

export const visitCountOfferFlowPersist = (
  state = visitCountState,
  action: { type: Offers_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.VISIT_COUNT_OFFERS_FLOW:
      return {
        ...state,
        visitCount: action.payload as VisitCount,
      }
    default:
      return state
  }
}

// TimeLeft Persist

interface TimeLeftState {
  timeLeft: number | null
}

const timeLeftState: TimeLeftState = {
  timeLeft: null,
}

export const SetTimeLeftOfferFlow = (timeLeft: number | null) => ({
  type: Offers_Flow_Action_Type.TIME_LEFT_OFFERS_FLOW,
  payload: timeLeft,
})

export const timeLeftOfferFlowPersist = (
  state = timeLeftState,
  action: { type: Offers_Flow_Action_Type; payload: any },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.TIME_LEFT_OFFERS_FLOW:
      return {
        ...state,
        timeLeft: action.payload,
      }
    default:
      return state
  }
}

// Studios

interface studiosOffers {
  studiosOffers: Array<studio>
}
const studiosOffersState: studiosOffers = {
  studiosOffers: [],
}

export const SetStudiosOffers = (studiosOffers: Array<studio>) => ({
  type: Offers_Flow_Action_Type.STUDIOS_OFFERS_FLOW,
  payload: studiosOffers,
})

export const studiosOffersPersist = (
  state = studiosOffersState,
  action: { type: Offers_Flow_Action_Type; payload: Array<studio> },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.STUDIOS_OFFERS_FLOW:
      return {
        ...state,
        studiosOffers: action.payload,
      }
    default:
      return state
  }
}

// Selected Studio

interface SelectedStudioOffers {
  studioOffers: studio | null
}
const selectedStudioOffersState: SelectedStudioOffers = {
  studioOffers: null,
}

export const SetSelectedStudioOffers = (selectedStudioOffers: studio | null) => ({
  type: Offers_Flow_Action_Type.STUDIO_OFFERS_FLOW,
  payload: selectedStudioOffers,
})

export const selectedStudioOffersPersist = (
  state = selectedStudioOffersState,
  action: { type: Offers_Flow_Action_Type; payload: studio },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.STUDIO_OFFERS_FLOW:
      return {
        ...state,
        studioOffers: action.payload,
      }
    default:
      return state
  }
}

// isMoreThanOneStudio

interface isMoreThanOneStudioOffers {
  isMoreThanOneStudioOffers: boolean
}
const isMoreThanOneStudioOffers: isMoreThanOneStudioOffers = {
  isMoreThanOneStudioOffers: false,
}

export const SetIsMoreThanOneStudioOffers = (isMoreThanOneStudioOffers: boolean) => ({
  type: Offers_Flow_Action_Type.IS_MORE_THAN_ONE_STUDIO_OFFERS,
  payload: isMoreThanOneStudioOffers,
})

export const isMoreThanOneStudioOffersPersist = (
  state = isMoreThanOneStudioOffers,
  action: { type: Offers_Flow_Action_Type; payload: boolean },
) => {
  switch (action.type) {
    case Offers_Flow_Action_Type.IS_MORE_THAN_ONE_STUDIO_OFFERS:
      return {
        ...state,
        isMoreThanOneStudioOffers: action.payload,
      }
    default:
      return state
  }
}
