import React, { useEffect, useMemo, useState } from 'react'
import { CircularProgress, Grid, Typography, Button, Dialog, DialogTitle } from '@mui/material'
import { Elements, useElements, useStripe } from '@stripe/react-stripe-js'
import { StripeElementsOptions, StripeExpressCheckoutElementOptions, loadStripe } from '@stripe/stripe-js'
import axios from 'axios'
import ReactGA from 'react-ga4'
import { useNavigate } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import Footer from 'components/Footer'
import Header from 'components/Header'
// import { analytics } from 'index'
import { IPlan } from 'redux/auth/Interface'
import {
  PlanDetails,
  SetAvailablePlans,
  SetIsMoreThanOneStudio,
  SetSelectedPlan,
  SetSelectedPlanInfo,
  SetStudios,
} from 'redux/persist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { StatusEnum } from 'redux/types'
import { capitalizeExcept } from 'utils/capitalizeExcept'
import styles from './ChoosePlan.module.scss'
import { CheckoutForm } from '../Payment/CheckoutForm'

const styless: any = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 300,
  height: 170,
  overflow: 'hidden',
  backgroundColor: '#050624',
  color: 'white',
  borderRadius: '8px',
  p: 4,
}

const ChoosePlan = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [clientSecret, setClientSecret]: any = useState(null)
  const [selectedPlanId, setSelectedPlanId] = useState('')
  const [error, setError] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [loadingButton, setLoadingButton] = useState(false)
  const plans = useAppSelector((state) => state.availablePlan.availablePlans)
  const [fetchPlanStatus, setFetchPlanStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [userInfo, setUserInfo]: any = useState(null)
  const selectedPlanInfo = useAppSelector((state) => state.selectedPlanInfo.selectedPlanInfo)
  const isMoreThanOneStudio = useAppSelector((state) => state.isMoreThanOneStudio.isMoreThanOneStudio)

  //cardPayment
  const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY || ''
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)

  const options: StripeElementsOptions = {
    clientSecret: clientSecret,
    appearance: {
      theme: 'night',
      variables: {
        colorBackground: '#050624',
      },
    },
  }

  let selectedPlan = useMemo(() => {
    if (plans && selectedPlanId) {
      return plans.filter((p: any) => p.id === selectedPlanId)[0]
    } else {
      return null
    }
  }, [plans, selectedPlanId])

  // plans order
  const planList = useMemo(() => {
    if (plans) {
      const membershipPlan = plans?.find((plan) => plan.type === 'subscription')
      const otherPlans = plans?.filter((plan) => plan.type !== 'subscription')
      return membershipPlan ? [membershipPlan, ...otherPlans] : otherPlans
    }
  }, [plans])

  //choose plan

  const onContinue = async () => {
    console.log('on continue')
    setLoadingButton(true)
    purchasePlan(selectedPlanInfo?.id)

    // if (selectedPlan) {
    //   ReactGA.event('begin_checkout', {
    //     currency: 'USD',
    //     value: selectedPlan.amount / 100,
    //     items: [
    //       {
    //         item_id: selectedPlan.id,
    //         item_name: selectedPlan.name,
    //       },
    //     ],
    //   })
    // }
  }

  async function getProfile() {
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      setUserInfo(response?.data?.data)
      if (response && response?.data?.data?.initialPayment === true) {
        // navigate('/complete-registration')
      } else if (response?.data?.statusCode === 401) {
        localStorage.clear()
        sessionStorage.clear()
        navigate('/login')
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function getPlans() {
    setFetchPlanStatus(StatusEnum.Pending)
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/payment-plan`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      if (response && response?.data && response?.data?.data) {
        dispatch(SetAvailablePlans(response?.data?.data))

        setFetchPlanStatus(StatusEnum.Success)
      } else if (response?.data?.statusCode === 401) {
        setFetchPlanStatus(StatusEnum.Failed)
        // navigate('/login')
        console.log('error')
        //call reset login
      }
    } catch (error) {
      setFetchPlanStatus(StatusEnum.Failed)
      console.error(error)
    }
  }

  async function purchasePlan(selectedPlanId: string | undefined) {
    try {
      const response: any = await axios
        .post(
          `${AppConstants.API_URL}/payment-plan/purchase`,
          { planId: selectedPlanId, quantity: 1 },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('userToken')}`,
              'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
            },
          },
        )
        .then((res) => {
          if (res && res?.data && res?.data?.data) {
            // setClientSecret(res?.data?.data?.clientSecret)
            // setPlanIdSecret(res?.data?.data?.paymentId)
            dispatch(SetSelectedPlan(res?.data?.data))

            if (isMoreThanOneStudio) {
              navigate('/select-studio')
            } else {
              navigate('/physical-visit')
            }
          }
        })
        .catch((err) => {
          if (err) {
            setLoadingButton(false)
            alert('Something went wrong. Please try again.')
          }
        })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getPlans()
    getProfile()
    if (selectedPlanInfo) {
      setDisableButton(false)
    }
  }, [])

  // useEffect(() => {
  //   analytics.page()
  // }, [])

  useEffect(() => {
    ReactGA.event('page_view', {
      page_title: 'Choose Plan',
      page_location: '/choose-plan',
    })
  }, [])

  return (
    <>
      <div className={styles.container}>
        <Header
          headerText="Choose a Plan"
          showBackButton={false}
          // onBack={() => {
          //   navigate('/add-photo')
          // }}
        ></Header>
        <div className={styles.planDetails}>
          {fetchPlanStatus === StatusEnum.Pending && (
            <div className={styles.loadingWrapper}>
              <CircularProgress style={{ color: '#007f7c' }} size={30}></CircularProgress>
            </div>
          )}
          {fetchPlanStatus === StatusEnum.Success && (
            <div>
              <form>
                <Grid container spacing={2}>
                  {planList?.map((plan: PlanDetails, index: number) => {
                    let membershipDescription: any = null
                    if (plan.type === 'subscription') {
                      let updatedDescriptions: any = [
                        `Unlimited Adjustments per Month`,
                        'Monthly Nervous System Scan',
                        'Renews Automatically',
                        'Cancel Anytime',
                      ]
                      membershipDescription = updatedDescriptions
                    }
                    return (
                      <Grid item xs={12} key={index}>
                        <div
                          onClick={(e) => {
                            // setSelectedPlanId(plan?.id)
                            dispatch(SetSelectedPlanInfo(plan))
                            setDisableButton(false)
                          }}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            padding: '10px 15px',
                            border: selectedPlanInfo?.id === plan.id ? '2px solid #007F7C' : '2px solid #2A2D56',
                            borderRadius: '6px',
                            backgroundColor: '#2A2D56',
                            color: '#E5E7EB',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div style={{ textTransform: 'capitalize' }}>
                              {plan?.type === 'subscription' && (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                  <img
                                    src={'/images/logoV3.svg'}
                                    style={{
                                      width: '22px',
                                      height: '22px',
                                    }}
                                    alt="logo"
                                  ></img>
                                  <span style={{ fontWeight: 700 }}>Membership</span>
                                </div>
                              )}
                              {/* {plan?.name === 'Membership (Popular)' && <span>Membership</span>} */}
                              {plan?.type === 'on-demand' && <span style={{ fontWeight: 700 }}>Single Adjustment</span>}
                            </div>
                            <span style={{ fontWeight: 600 }}>
                              $ {plan.type === 'subscription' ? `${plan.amount / 100}/mo.` : plan.amount / 100}
                            </span>
                          </div>
                          {membershipDescription !== null && (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                              }}
                            >
                              {membershipDescription.map((list: any, index: number) => {
                                return (
                                  <div
                                    key={index}
                                    style={{
                                      display: 'flex',
                                      gap: '16px',
                                      alignItems: 'center',
                                      marginTop: '8px',
                                      marginLeft: '6px',
                                    }}
                                  >
                                    <img
                                      src={'/images/bullets.svg'}
                                      style={{ width: '8px', height: '8px' }}
                                      alt="tick"
                                    ></img>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        fontSize: '14px',
                                        fontWeight: 100,
                                      }}
                                    >
                                      {list}
                                      {index === 0 && (
                                        <img
                                          style={{
                                            height: '16px',
                                            width: '16px',
                                            marginLeft: '6px',
                                            cursor: 'pointer',
                                          }}
                                          src="/images/info.svg"
                                          alt="i"
                                          onClick={() => {
                                            setShowInfoModal(true)
                                          }}
                                        ></img>
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                              {/* <p>{plan?.description}</p> */}
                            </div>
                          )}
                        </div>
                      </Grid>
                    )
                  })}
                </Grid>
              </form>
              {/* <div
                style={{
                  color: '#E5E7EB',
                  fontSize: '16px',
                  marginTop: '30px',
                  marginLeft: '1px',
                }}
              >
                <b>All Plans Include a Complimentary Screening</b>
                <br />
                <div style={{ marginTop: '1rem', lineHeight: '1.5rem' }}>
                  Following your screening, if you choose to opt-out, we'll refund you 100%.
                </div>
              </div> */}
            </div>
          )}
        </div>

        <div className={styles.btnWrapper}>
          <Grid item xs={12}>
            {showInfoModal && (
              <Dialog
                onClose={() => {}}
                open={showInfoModal}
                PaperProps={{ style: styless }}
                className={styles.modalPopUp}
              >
                <DialogTitle style={{ fontSize: '14px', marginTop: '0rem', marginLeft: '10px' }}>
                  KIRO doctors will recommend the ideal frequency based on your symptoms.
                </DialogTitle>
                <Button
                  color="primary"
                  style={{
                    width: 'fit-content',
                    marginBottom: '1rem',
                    height: '2rem',
                    padding: '0px 8px 0px 8px',
                    fontSize: '12px',
                  }}
                  onClick={() => {
                    setShowInfoModal(false)
                  }}
                >
                  Ok
                </Button>
              </Dialog>
            )}
            {/* {er
            {/* {error && (
              <div className={styles.errorContainer}>
                <Typography sx={{ fontSize: '14px', fontWeight: 700 }}>Please choose a plan you want ?</Typography>
                <div
                  style={{
                    display: 'flex',
                    flex: '1',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: '12px',
                  }}
                >
                  <img
                    onClick={() => {
                      setError(false)
                    }}
                    style={{ cursor: 'pointer' }}
                    src={'images/wrong.svg'}
                  ></img>
                </div>
              </div>
            )} */}
          </Grid>
        </div>
        <Footer
          buttonText={'Continue'}
          isLoading={loadingButton}
          trackerIndex={1}
          shouldShowFour={true}
          shouldShowFive={isMoreThanOneStudio}
          isDisabled={disableButton}
          buttonType={'submit'}
          onButtonClick={() => {
            disableButton ? undefined : onContinue()
          }}
        ></Footer>
      </div>

      {/* {!showPlan && (
        <div className={styles.paymentContainer}>
          <Header
            headerText="Add Payment Details"
            showBackButton={true}
            onBack={() => {
              disableBackButton ? undefined : setShowPlan(true)
            }}
          ></Header>
          <Elements stripe={stripePromise} options={options}>
            <div className={styles.paymentContainerDetails}>
              <CheckoutForm
                clientSecret={clientSecret}
                planId={planIdSecret}
                purchaseAmount={selectedPlan ? selectedPlan.amount / 100 : 60}
                plan={selectedPlan}
                disableBackButton={() => {
                  setDisableBackButton(true)
                }}
                enableBackButton={() => {
                  setDisableBackButton(false)
                }}
                showPlan={() => {
                  setShowPlan(true)
                }}
                userInfo={userInfo}
              ></CheckoutForm>
            </div>
          </Elements>
        </div>
      )} */}
    </>
  )
}

export default ChoosePlan
