import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@mui/material'
import axios from 'axios'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { AppConstants } from 'AppConstants/AppConstants'
import {
  SetAvailablePlansBooking,
  SetIsMoreThanOneStudioBooking,
  SetLeadType,
  SetPaymentDoneBooking,
  SetPurchasedPlanBooking,
  SetReferralCodeBooking,
  SetSelectedPlanBooking,
  SetSelectedSlotBooking,
  SetSelectedStudioBooking,
  SetSlotBookedAlreadyBooking,
  SetSlotNotFoundBooking,
  SetStudiosBooking,
  SetTimeLeftToExpireBooking,
  SetVisitCountBooking,
} from 'redux/booking'
import { SetSkipCongratsBooking } from 'redux/booking/nonPersist'
import { useAppDispatch, useAppSelector } from 'redux/store'
import { StatusEnum } from 'redux/types'
import styles from './Booking.module.scss'

export default function Booking() {
  const [fetchTokenStatus, setFetchTokenStatus] = useState(StatusEnum.Idle)
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isOfferOrReferral = useAppSelector((state) => state.booking.leadType)

  console.log(isOfferOrReferral, 'is the isOfferOrReferral')
  //
  async function fetchToken() {
    setFetchTokenStatus(StatusEnum.Pending)
    const response: any = await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/auth/generate-token/${id}`, {
        headers: {
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      .then((res) => res)
      .catch((err) => err.response)

    if (response && response.data && response.data.code === 'success') {
      localStorage.setItem(AppConstants.ACCESS_TOKEN, response?.data?.data?.accessToken)
      let localToken = response.data?.data?.accessToken
      let tokenParts = localToken.split('.')
      let tokenPayload = tokenParts[1]
      let tokenPayloadStr = atob(tokenPayload)
      let decodedToken: any = JSON.parse(tokenPayloadStr)
      let tempCurrentRole: any = ''
      if (decodedToken) {
        if (decodedToken.roles && decodedToken.roles.length === 1) {
          tempCurrentRole = decodedToken.roles[0]
        }
      } else {
        tempCurrentRole = null
      }
      localStorage.setItem(AppConstants.CURRENTROLE_TOKEN, tempCurrentRole)
      setFetchTokenStatus(StatusEnum.Success)
    } else if (response && response?.data && response.data.code === 'user_not_found') {
      setFetchTokenStatus(StatusEnum.Failed)
      alert('User not found')
      navigate(`/`)
    }
  }

  async function getPlans(campaignCode: string) {
    try {
      const response: any = await axios.get(`${AppConstants.API_URL}/payment-plan?campaign=${campaignCode}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      if (response && response?.data && response?.data?.data) {
        console.log(response?.data?.data, 'plans')
        dispatch(SetAvailablePlansBooking(response?.data?.data))
      } else if (response?.data?.statusCode === 401) {
        console.log('error')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      return response?.data
    } catch (error) {
      console.error('Error fetching profile:', error)
      return null
    }
  }

  const fetchStudios = async () => {
    try {
      const response = await axios.get(`${AppConstants.API_URL}/clinic`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('userToken')}`,
          'x-platform-id': process.env.REACT_APP_PLATFORM_ID || '',
        },
      })
      return response?.data
    } catch (error) {
      console.error('Error fetching studios:', error)
      return null
    }
  }

  const handleRoutingLogic = (data: any, isMoreThanOneStudio: boolean, id: any) => {
    const { referralCode, previousVisits, leadType } = data
    dispatch(SetLeadType(leadType))

    if (referralCode === null && !previousVisits) {
      navigate(`/booking/${id}/choose-plan`)
    } else if (leadType !== null && !previousVisits) {
      if (isMoreThanOneStudio) {
        navigate(`/booking/${id}/select-studio`)
      } else {
        navigate(`/booking/${id}/physical-visit`)
      }
    } else {
      navigate(`/booking/${id}/download`)
    }
  }

  async function onContinue() {
    try {
      // Fetch profile data
      const profileResponse = await fetchProfile()
      if (!profileResponse || profileResponse?.code !== 'success') {
        navigate(`/booking/${id}/download`)
      }

      const profile = profileResponse?.data
      console.log(profile, 'profile obj')

      // Dispatch profile-related data
      dispatch(
        SetVisitCountBooking({
          onDemandCount: profile?.onDemandCount ?? null,
          subscriptionCount: profile?.subscriptionCount ?? null,
          previousVisits: profile?.previousVisits ?? null,
        }),
      )
      dispatch(SetReferralCodeBooking(profile?.referralCode))
      dispatch(SetPaymentDoneBooking(profile?.initialPayment))

      // Fetch studios
      const studiosResponse = await fetchStudios()
      const isMoreThanOneStudio = studiosResponse?.code === 'success' && studiosResponse?.data?.length > 1

      if (studiosResponse?.code === 'success') {
        dispatch(SetStudiosBooking(studiosResponse?.data))
        dispatch(SetIsMoreThanOneStudioBooking(isMoreThanOneStudio))
      }

      // Get plans if referralCode exists
      if (profile?.referralCode) {
        getPlans(profile.referralCode)
      }

      // Handle routing logic
      handleRoutingLogic(profile, isMoreThanOneStudio, id)
    } catch (error) {
      console.error('Error in getProfile:', error)
    }
  }

  useEffect(() => {
    if (fetchTokenStatus === StatusEnum.Idle) {
      fetchToken()
    }
    if (fetchTokenStatus === StatusEnum.Success) {
      onContinue()
    }
  }, [fetchTokenStatus])

  useEffect(() => {
    dispatch(SetSelectedPlanBooking(null))
    dispatch(SetSelectedSlotBooking(null))
    dispatch(SetAvailablePlansBooking(null))
    dispatch(SetSlotBookedAlreadyBooking(false))
    dispatch(SetSlotNotFoundBooking(false))
    dispatch(SetPurchasedPlanBooking(null))
    dispatch(SetVisitCountBooking(null))
    dispatch(SetPaymentDoneBooking(false))
    dispatch(SetReferralCodeBooking(null))
    dispatch(SetTimeLeftToExpireBooking(null))
    dispatch(SetSelectedStudioBooking(null))
    dispatch(SetStudiosBooking([]))
    dispatch(SetIsMoreThanOneStudioBooking(false))
    dispatch(SetLeadType(null))
  }, [])

  return (
    <div className={styles.container}>
      {fetchTokenStatus === StatusEnum.Pending && (
        <div className={styles.circularProgress}>
          <CircularProgress
            size={40}
            sx={{
              color: '#007F7C',
              width: '14px',
              height: '14px',
            }}
          />
        </div>
      )}
    </div>
  )
}
